<template>
    <div class="updata_box">
        <div style="width: 100%;height: 50px;"></div>
        <div class="updata_box_cenete">
            <div class="updata_div_top">
                <div style="width: 100%; display: flex; align-items: center; justify-content: space-evenly;">
                    <!-- <span :class="isactive == 1 ? 'personala' : 'personal'" @click="personal(1)">创意设计组</span>
                    <span :class="isactive == 2 ? 'personala' : 'personal'" @click="personal(2)">文创产品组</span> -->
                    <div :class="isactive == 1 ? 'personala' : 'personal'" @click="personal(1)">
                        <span>创意设计组</span>
                    </div>
                    <div :class="isactive == 2 ? 'personala2' : 'personal'" @click="personal(2)">
                        <span>文创产品组</span>
                    </div>
                </div>
            </div>
            <div class="updata_div">
                <div class="updata_div_toptext2">
                    <img style="width: 156px;height: 84px;" src="../../../../static/wenchang/金奖.png" alt="">
                </div>
                <img style="width: 100%;" src="../../../../static/wenchang/101.png" alt="">
                <div class="zuopinglist">
                    <div class="zuopingitem" v-for="(item, index) in fileList2">
                        <div class="zuopingitem_img" @click="gotoxiangq(item)">
                            <el-image style="width: 100%; height: 100%" :src="item.cover" fit="fill"></el-image>
                        </div>
                        <div class="zuopingitem_txt">
                            <div
                                style="font-weight: 600;font-size: 22px;color: #333333;margin-left: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                {{
                        item.artworkTitle }}</div>
                            <div
                                style="font-size: 16px;color: #000;margin-left: 15px;margin-right: 15px; display: flex;align-items: center;justify-content: space-between;">
                                <span>作者:{{ item.authorName }}</span>
                                <img style="width: 110px;height: 37px;cursor: pointer;" @click="gotoxiangq(item)" src="../../../../static/wenchang/查看.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 100%;height: 50px;"></div>
        <img class="updata_bottom" src="../../../../static/wenchang/4.png" alt="">
    </div>
</template>

<script>
import { selectPageList, artworkvote } from "../../../util/api"
export default {
    name: 'science_child2',

    data() {
        return {
            value: '',
            page: 1,
            currpage: 9,
            totalCount: 0,
            fileList2: [],
            isactive: 1,
            keyWord: '',
            actualType: '创意设计组',
            userId: localStorage.getItem('useinfo')
        }
    },
    mounted() {

    },
    created() {
        this.getlist()
    },
    methods: {
        gotoxiangq(val) {
            window.open('https://wenchuang-web.123bingo.cn/#/science_child7?id=' + val.id + '&liu=1', '_blank');
        },
        personal(val) {
            this.isactive = val
            this.page = 1
            if (val == 1) {
                this.actualType = '创意设计组'
            } else if (val == 2) {
                this.actualType = '文创产品组'
            }
            this.getlist()
        },
        getlist() {
            selectPageList({
                page: this.page,
                size: this.currpage,
                keyWord: this.keyWord,
                enroll: 1,
                actualType: this.actualType,
                userId: this.userId,
                soldOut: 0,
            }).then((res) => {
                console.log(res.data);
                const arr = []
                res.data.artworkInfoDao.forEach(item => {
                    if (item.cover) {
                        arr.push(item)
                    }
                });
                this.fileList2 = arr;
            })
        },
    }
}
</script>
<style scoped>
.submits {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding-bottom: 100px;
    padding-top: 50px;
}

.submit {
    width: 100%;
    text-align: center;
    padding-bottom: 100px;
    padding-top: 50px;
    cursor: pointer;
}

.updata_bottom {
    /* position: fixed;
    bottom: 0 */
    width: 100%;
    margin-bottom: -6px;
}

.updata_box_cenete {
    margin: 61px auto;
    width: 1311px;
    background: #FFFFFF;
    border-radius: 30px;
}

.updata_box {
    background-image: url("../../../../static/wenchang/8.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.updata_div {
    position: relative;
    padding: 0 71px;
    padding-top: 42px;
    width: 100%;
    height: 100%;
}

.updata_div_toptext {
    position: absolute;
    top: 4%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 28px;
    color: #333333;
}

.updata_div_toptext2 {
    position: absolute;
    top: 4%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 28px;
    color: #333333;
}

.input1 {
    width: 100%;
    display: flex;
    align-items: center;
}

.input2 {
    width: 100%;
    display: flex;
    align-items: center;
}

.input3 {
    width: 100%;
    display: flex;
}

.select1 {
    display: flex;
    align-items: center;
}

.select2 {}

.input3uplodtxt {
    font-weight: 100;
    font-size: 14px;
    color: #F34242;
    margin-top: 20px;
    margin-left: 152px;
}

.el-upload__tip {
    font-size: 14px;
    color: #F34242;
}

.personal {
    cursor: pointer;
    text-align: center;
    width: 268px;
    height: 29px;
    background-image: url('../../../../static/wenchang/100.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    font-size: 20px;
    line-height: 29px;
    opacity: 0.5;
}

.personala {
    cursor: pointer;
    text-align: center;
    width: 268px;
    height: 29px;
    background-image: url('../../../../static/wenchang/100.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    font-size: 20px;
    line-height: 29px;
}

.personala2 {
    cursor: pointer;
    text-align: center;
    width: 268px;
    height: 29px;
    background-image: url('../../../../static/wenchang/100.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    font-size: 20px;
    line-height: 29px;
}

.personal_box {
    position: absolute;
    top: 7%;
    left: 7%;
}

@media (max-width: 480px) {}

@media (max-width: 5000px) {
    p {
        font-size: 14px !important;
    }

    .updata_div_top {
        width: 100%;
        height: 96px;
        background: #43A1CC;
        border-radius: 30px 30px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .zuopinglist {
        margin-top: 50px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .zuopinglist .zuopingitem:last-child:nth-child(3n-2) {
        margin-right: calc(60% + (10% / 4) * 2);
    }

    .zuopinglist .zuopingitem:last-child:nth-child(3n-1) {
        margin-right: calc(30% + (10% / 4) * 1);
    }

    .zuopingitem {
        text-align: center;
        width: 512px;
    }

    .zuopingitem_img {
        padding: 11px;
        padding-bottom: 0px;
        width: 100%;
        height: 297px;
        border-radius: 16px 16px 0px 0px;
    }

    .zuopingitem_txt {
        margin: 0px 11px;
        background: #EEEEEE;
        border-radius: 0px 0px 16px 16px;
        text-align: left;
        padding-bottom: 7px;
    }

    .zuopingitem_txt div {
        padding: 8px 0;
    }

    .toupiao {
        width: 133px;
        height: 42px;
        margin: 29px auto;
        padding: 0 !important;
    }
}
</style>